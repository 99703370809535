import * as Sentry from '#utils/sentry';
import { carrotDomain } from 'utils/carrotDomain';
import { carrotEnvironment } from 'utils/carrotEnvironment';
import { supportedBrowsers } from 'generated/supportedBrowsers';

export function initializeSentry() {
  // Sentry can be disabled using this env var if needed.
  // This is primarily for local dev.
  if (import.meta.env.VITE_DISABLE_SENTRY === 'true') return;

  // Don't initialize Sentry for unsupported browsers.
  // Ref: https://help.carrot.com/tutorial/supported-browsers/
  if (!supportedBrowsers.test(navigator.userAgent)) return;

  Sentry.init({
    dsn: 'https://364850ef6b1b4c3690ca163a6d2e0d51@o62767.ingest.sentry.io/6184267',
    environment: carrotEnvironment(carrotDomain()),
  });
}
